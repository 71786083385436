var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "mr-5" },
    [
      _c(
        "b-row",
        { staticClass: "mt-5" },
        [
          _c(
            "b-col",
            [
              _c(
                "b-link",
                {
                  staticClass: "link-color link-underline font-weight-bold",
                  attrs: { to: "dashpanel" },
                },
                [
                  _c("fa-icon", {
                    staticClass: "fa-sm pl-1 arrow",
                    attrs: { icon: "chevron-left" },
                  }),
                  _vm._v("\n        Home\n      "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("h3", { staticClass: "font-weight-bold" }, [
              _vm._v("Contact Support"),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", [
            _c(
              "p",
              { staticClass: "font-weight-normal" },
              [
                _vm._v("\n        Explore our\n        "),
                _c(
                  "b-link",
                  {
                    staticClass: "link-color link-underline fw-600",
                    attrs: { to: "faq" },
                  },
                  [_vm._v("\n        Frequently Asked Questions\n        ")]
                ),
                _vm._v("\n        or contact our support team:\n      "),
              ],
              1
            ),
            _vm.address
              ? _c("div", { staticClass: "mb-2" }, [
                  _c("h5", { staticClass: "fw-500" }, [
                    _vm._v(_vm._s(this.website.name)),
                  ]),
                  _c("span", { staticClass: "font-weight-normal" }, [
                    _vm._v(_vm._s(this.address.line1)),
                  ]),
                  _c("br"),
                  _c("span", { staticClass: "font-weight-normal" }, [
                    _vm._v(_vm._s(this.address.line2)),
                  ]),
                  _c("br"),
                  _c("span", { staticClass: "font-weight-normal" }, [
                    _vm._v(
                      _vm._s(this.address.city) +
                        ", " +
                        _vm._s(this.address.state_province_region) +
                        " " +
                        _vm._s(this.address.zip_postal_code)
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", [
            _vm.phone
              ? _c(
                  "div",
                  { staticClass: "mb-2" },
                  [
                    _c("contact-phone-icon", { staticClass: "mr-2" }),
                    _c("span", {
                      directives: [
                        {
                          name: "phone-number",
                          rawName: "v-phone-number",
                          value: _vm.phone,
                          expression: "phone",
                        },
                      ],
                      staticClass: "link-color link-underline",
                      attrs: { title: "Telephone" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", [
            _vm.email
              ? _c(
                  "div",
                  [
                    _c("contact-email-icon", { staticClass: "mr-2" }),
                    _c("span", { staticClass: "link-color link-underline" }, [
                      _vm._v(_vm._s(this.email)),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }