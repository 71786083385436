<template>
  <b-container class="mr-5">
    <b-row class="mt-5">
      <b-col>
        <b-link class="link-color link-underline font-weight-bold" :to="'dashpanel'">
          <fa-icon icon="chevron-left" class="fa-sm pl-1 arrow" />
          Home
        </b-link>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h3 class="font-weight-bold">Contact Support</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <p class="font-weight-normal">
          Explore our
          <b-link class="link-color link-underline fw-600" :to="'faq'">
          Frequently Asked Questions
          </b-link>
          or contact our support team:
        </p>
        <div v-if="address" class="mb-2">
          <h5 class="fw-500">{{this.website.name}}</h5>
          <span class="font-weight-normal">{{this.address.line1}}</span><br>
          <span class="font-weight-normal">{{this.address.line2}}</span><br>
          <span class="font-weight-normal">{{this.address.city}}, {{this.address.state_province_region}} {{this.address.zip_postal_code}}</span>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div v-if="phone" class="mb-2">
          <contact-phone-icon class="mr-2" />
          <span v-phone-number="phone" title="Telephone" class="link-color link-underline" />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div v-if="email">
          <contact-email-icon class="mr-2" />
          <span class="link-color link-underline">{{this.email}}</span>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { phoneNumber } from '@/directives/directives'

export default {
  name: 'ContactSupport',
  components: {
    ContactEmailIcon: () => import('@images/ui/contact-email-icon.svg'),
    ContactPhoneIcon: () => import('@images/ui/contact-phone-icon.svg'),
  },
  directives: { phoneNumber },
  computed: {
    ...mapGetters('website', ['website']),
    address() {
      return this.website.addresses.find((a) => a.kind === 'primary' || a.kind === 'default') || null
    },
    email() {
      if (!this.website || !Array.isArray(this.website.emails)) {
        return null
      }
      return this.website.emails[0]
    },
    phone() {
      if (!this.website || !Array.isArray(this.website.phone_numbers)) {
        return null
      }
      return (this.website.phone_numbers.find((a) => a.kind === 'billing') || {}).phone_number
    },
  },
}
</script>

<style lang="scss" scoped>
  .link-color { color: #2FB2AE !important; }
  .link-underline { text-decoration: underline !important; }
  .fw-500 { font-weight: 500; }
  .fw-600 { font-weight: 600; }
</style>
